

export const createColumn = (index: any, t: any) => {
  if (index == 0) {
    return [
      {
        field: "producer_name",
        headerName: t("Dashboard.table.producer_name"),
        width: 150,
      },
      {
        field: "total_ha",
        type: "number",
        headerName: t("Dashboard.table.total_ha"),
        width: 100,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
                paddingLeft: "10px",
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },
      },
      {
        field: "sowed_percentage",
        headerName: t("Dashboard.table.sowed_percentage"),
        type: "float",
        width: 100,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
                paddingLeft: "10px",
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },

        renderCell: (params: any) => {
          const value = params.value != null ? (params.value * 100).toFixed(2) : 0;
          return `${value}%`;
        },
      },
      {
        field: "latest_sowing_date",
        headerName: t("Dashboard.table.latest_sowing_date"),
        type: "date",
        width: 150,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },
      },
      {
        field: "risk_percentage",
        headerName: t("Dashboard.table.risk_percentage"),
        type: "float",
        width: 100,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
                paddingLeft: "10px",
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },

        renderCell: (params: any) => {
          const value = params.value != null ? (params.value * 100).toFixed(2) : 0;
          return `${value}%`;
        },
      },
      {
        field: "estimated_yield",
        headerName: t("Dashboard.table.estimated_yield"),
        type: "number",
        width: 150,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },
      },
      {
        field: "estimated_yield_unit",
        headerName: t("Dashboard.table.estimated_yield_unit"),
        width: 180,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },
      },
      {
        field: "harvested_percentage",
        headerName: t("Dashboard.table.harvested_percentage"),
        width: 100,
        type: "float",
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
                paddingLeft: "10px",
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },

        renderCell: (params: any) => {
          const value = params.value != null ? (params.value * 100).toFixed(2) : 0;
          return `${value}%`;
        },
      },


      {
        field: "latest_harvest_date",
        headerName: t("Dashboard.table.latest_harvest_date"),
        type: "date",
        width: 150,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },
      },
    ];
  }
  if (index == 1) {
    return [
      {
        field: "producer_name",
        headerName: t("Dashboard.table.producer_name"),
        width: 150,
      },
      {
        field: "crop",
        headerName: t("Dashboard.table.crop"),
        width: 100,
      },
      {
        field: "total_ha",
        type: "number",
        headerName: t("Dashboard.table.total_ha"),
        width: 90,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
                paddingLeft: "10px",
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },
      },
      {
        field: "sowed_percentage",
        headerName: t("Dashboard.table.sowed_percentage"),
        type: "float",
        width: 90,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
                paddingLeft: "10px",
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },

        renderCell: (params: any) => {
          const value = params.value != null ? (params.value * 100).toFixed(2) : 0;
          return `${value}%`;
        },
      },
      {
        field: "latest_sowing_date",
        headerName: t("Dashboard.table.latest_sowing_date"),
        type: "date",
        width: 150,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },
      },
      {
        field: "risk_percentage",
        headerName: t("Dashboard.table.risk_percentage"),
        type: "float",
        width: 80,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
                paddingLeft: "10px",
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },

        renderCell: (params: any) => {
          const value = params.value != null ? (params.value * 100).toFixed(2) : 0;
          return `${value}%`;
        },
      },
      {
        field: "estimated_yield",
        headerName: t("Dashboard.table.estimated_yield"),
        type: "number",
        width: 120,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },
      },
      {
        field: "estimated_yield_unit",
        headerName: t("Dashboard.table.estimated_yield_unit"),
        width: 180,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },
      },
      {
        field: "harvested_percentage",
        headerName: t("Dashboard.table.harvested_percentage"),
        width: 100,
        type: "float",
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
                paddingLeft: "10px",
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },

        renderCell: (params: any) => {
          const value = params.value != null ? (params.value * 100).toFixed(2) : 0;
          return `${value}%`;
        },
      },
      {
        field: "latest_harvest_date",
        headerName: t("Dashboard.table.latest_harvest_date"),
        type: "date",
        width: 130,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },
      },
    ];
  }
  if (index == 2) {
    return [
      {
        field: "producer_name",
        headerName: t("Dashboard.table.producer_name"),
        width: 130,
      },
      {
        field: "farm_name",
        headerName: t("Dashboard.table.farm_name"),
        width: 120,
      },
      {
        field: "total_ha",
        type: "number",
        headerName: t("Dashboard.table.total_ha"),
        width: 90,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
                paddingLeft: "10px",
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },
      },
      {
        field: "sowed_percentage",
        headerName: t("Dashboard.table.sowed_percentage"),
        type: "float",
        width: 100,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
                paddingLeft: "10px",
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },

        renderCell: (params: any) => {
          const value = params.value != null ? (params.value * 100).toFixed(2) : 0;
          return `${value}%`;
        },
      },
      {
        field: "latest_sowing_date",
        headerName: t("Dashboard.table.latest_sowing_date"),
        type: "date",
        width: 120,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },
      },
      {
        field: "risk_percentage",
        headerName: t("Dashboard.table.risk_percentage"),
        type: "float",
        width: 80,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
                paddingLeft: "10px",
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },

        renderCell: (params: any) => {
          const value = params.value != null ? (params.value * 100).toFixed(2) : 0;
          return `${value}%`;
        },
      },
      {
        field: "estimated_yield",
        headerName: t("Dashboard.table.estimated_yield"),
        type: "number",
        width: 150,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },
      },
      {
        field: "estimated_yield_unit",
        headerName: t("Dashboard.table.estimated_yield_unit"),
        width: 180,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },
      },
      {
        field: "harvested_percentage",
        headerName: t("Dashboard.table.harvested_percentage"),
        width: 100,
        type: "float",
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
                paddingLeft: "10px",
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },

        renderCell: (params: any) => {
          const value = params.value != null ? (params.value * 100).toFixed(2) : 0;
          return `${value}%`;
        },
      },
      {
        field: "latest_harvest_date",
        headerName: t("Dashboard.table.latest_harvest_date"),
        type: "date",
        width: 120,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },
      },
    ];
  }
  if (index == 3) {
    return [
      {
        field: "producer_name",
        headerName: t("Dashboard.table.producer_name"),
        width: 100,
      },
      {
        field: "farm_name",
        headerName: t("Dashboard.table.farm_name"),
        width: 120,
      },
      {
        field: "crop",
        headerName: t("Dashboard.table.crop"),
        width: 80,
      },
      {
        field: "total_ha",
        type: "number",
        headerName: t("Dashboard.table.total_ha"),
        width: 90,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
                paddingLeft: "10px",
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },
      },
      {
        field: "sowed_percentage",
        headerName: t("Dashboard.table.sowed_percentage"),
        type: "float",
        width: 90,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
                paddingLeft: "10px",
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },

        renderCell: (params: any) => {
          const value = params.value != null ? (params.value * 100).toFixed(2) : 0;
          return `${value}%`;
        },
      },
      {
        field: "latest_sowing_date",
        headerName: t("Dashboard.table.latest_sowing_date"),
        type: "date",
        width: 120,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },
      },
      {
        field: "risk_percentage",
        headerName: t("Dashboard.table.risk_percentage"),
        type: "float",
        width: 80,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
                paddingLeft: "10px",
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },

        renderCell: (params: any) => {
          const value = params.value != null ? (params.value * 100).toFixed(2) : 0;
          return `${value}%`;
        },
      },
      {
        field: "estimated_yield",
        headerName: t("Dashboard.table.estimated_yield"),
        type: "number",
        width: 120,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },
      },
      {
        field: "estimated_yield_unit",
        headerName: t("Dashboard.table.estimated_yield_unit"),
        width: 170,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },
      },
      {
        field: "harvested_percentage",
        headerName: t("Dashboard.table.harvested_percentage"),
        width: 100,
        type: "float",
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
                paddingLeft: "10px",
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },

        renderCell: (params: any) => {
          const value = params.value != null ? (params.value * 100).toFixed(2) : 0;
          return `${value}%`;
        },
      },
      {
        field: "latest_harvest_date",
        headerName: t("Dashboard.table.latest_harvest_date"),
        type: "date",
        width: 120,
        align: "center",
        renderHeader: (params: any) => {
          return (
            <div
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                display: "flex",
                alignItems: "center",
                height: "auto",
                lineHeight: "normal",
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              {params.colDef.headerName}
            </div>
          );
        },
      },
    ];
  }
  return [];
};
