import * as React from "react";
import { DataGrid, esES, GridColDef } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

type PropsDataTableDash = {
  rows: object[];
  columns: GridColDef[];
};

export const DataTableDashboard = ({ rows, columns }: PropsDataTableDash) => {
  const { i18n } = useTranslation();
  return (
    <Box
      sx={{
        height: { xs: "75vh", md: "75vh", lg: "75vh", xl: "90vh" },
        minWidth: "100%",
      }}
    >
      <DataGrid
        sx={{
          ".MuiDataGrid-cell--textLeft": {
            justifyContent: "left",
            fontSize: ".8rem",
          },
          ".MuiDataGrid-cell--textRight": {
            justifyContent: "left",
          },
          ".MuiDataGrid-columnHeaderTitleContainer": {
            justifyContent: "left",
            color: "#005F40",
            fontWeight: "bold",
          },

          color: "rgba(0, 0, 0, 0.87)",
        }}
        rows={rows}
        getRowId={(item) => {
          let id: any = "";
          if (item?.crop) {
            if (item?.producer_id) {
              id = `${item?.producer_id}_${item?.crop}`;
            }
            if (item?.farm_id) {
              id = `${item?.farm_id}_${item?.crop}`;
            }
          } else {
            id = item?.producer_id || item?.farm_id || "";
          }

          return id;
        }}
        columns={columns}
        rowBuffer={10}
        rowThreshold={10}
        initialState={{
          pagination: {
            pageSize: 99,
          },
        }}
        {...(i18n.language === "es" && {
          localeText: esES.components.MuiDataGrid.defaultProps.localeText,
        })}
        onRowClick={(params, event) => {
          if (!event.bubbles) {
            console.log("push -> /roles/" + params.row.field_id);
          }
        }}
      />
    </Box>
  );
};
