import { useQuery } from "@tanstack/react-query";
import { Producers } from "../components/maps/interface/interface-filter-map";
import { apiRequest } from "../api/apiRequests";
import { useAuth } from "../context/auth-context";

const baseApi = process.env.NEXT_PUBLIC_BASE_API;

const getSummaryGroupCrop = async (userId: number, did: any): Promise<any> => {
  const response = apiRequest(
    `${baseApi}/get_summary/producer/crop/${userId}`,
    "GET",
    null,
    did
  );
  return await response;
};

export const useSummaryGroupCrop = (userId: any, enabled: any) => {
  const { token } = useAuth() as any;
  const summaryGroupCropQuery = useQuery(
    ["summaryGroupCrop", userId],
    () => getSummaryGroupCrop(userId, token),
    {
      enabled: !!userId && enabled,
      cacheTime: 300000,
    }
  );
  return summaryGroupCropQuery;
};
