import React, { useState } from "react";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { DataTable } from "../data-alert/data-table-alert";
import { DataTableDashboard } from "./table-dashboard";
import { createColumn } from "./create-column";
import { exportToExcel } from "../../util/export-xlsx";
import { useTranslation } from "react-i18next";
import { GridAlignment } from "@mui/x-data-grid";
import { useSummaryGroup } from "../../hooks/useSummaryGroup";
import { useSummaryFarm } from "../../hooks/useSummaryFarm";
import { useSummaryGroupCrop } from "../../hooks/useSummaryGroupCrop";
import { useSummaryFarmCrop } from "../../hooks/useSummaryFarmCrop";

type dashboardProps = {
  userId: any;
};

const TabPanel = ({ children, value, index }: any) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
};

export const DashboardTemplate = ({ userId }: dashboardProps) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [row, setRow] = useState([]);
  const [column, setColumn] = useState(createColumn(0, t)) as any;

  const getGroupSummary = useSummaryGroup(userId, activeTab == 0);
  const getGroupCropSummary = useSummaryGroupCrop(userId, activeTab == 1);
  const getFarmSummary = useSummaryFarm(userId, activeTab == 2);
  const getFarmCropSummary = useSummaryFarmCrop(userId, activeTab == 3);

  const handleTabChange = (event: any, newValue: any) => {
    setActiveTab(newValue);
    setColumn(createColumn(newValue, t));
  };

  return (
    <div>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        variant="fullWidth"
        sx={{
          ml: { xs: "2%", md: "2.5%" },
          mr: "1%",
          mt: { xs: "20%", sm: "2%", md: "2%" },
        }}
      >
        <Tab
          label={t("Dashboard.filter.group")}
          sx={{ fontSize: { xs: ".8rem" } }}
        />
        <Tab
          label={t("Dashboard.filter.groupCrop")}
          sx={{ fontSize: { xs: ".8rem" } }}
        />
        <Tab
          label={t("Dashboard.filter.farm")}
          sx={{ fontSize: { xs: ".8rem" } }}
        />
        <Tab
          label={t("Dashboard.filter.farmCrop")}
          sx={{ fontSize: { xs: ".8rem" } }}
        />
      </Tabs>
      <TabPanel value={activeTab} index={0}>
        {getGroupSummary.isLoading ? (
          <CircularProgress
            size={70}
            sx={{ color: "#005F40", ml: "50%", mt: "25%" }}
          />
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            ml={{ xs: "1%", sm: "3.5%", md: "2.5%" }}
            mr="1%"
            mt="2%"
          >
            <DataTableDashboard columns={column} rows={getGroupSummary.data} />
            <Box display="flex" width="100%" justifyContent="end">
              <Button
                variant="contained"
                size="small"
                sx={{
                  mt: "2%",
                  backgroundColor: "#005f40",
                  color: "#EDEDED",
                  fontSize: ".8rem",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#005f40",
                    color: "#EDEDED",
                  },
                }}
                onClick={() =>
                  exportToExcel(
                    getGroupSummary.data.map(
                      ({ producer_id, ...rest }: any) => rest
                    ),
                    t("Dashboard.filter.group")
                  )
                }
              >
                {t("Dashboard.excel")}
              </Button>
            </Box>
          </Box>
        )}
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        {getGroupCropSummary.isLoading ? (
          <CircularProgress
            size={70}
            sx={{ color: "#005F40", ml: "50%", mt: "25%" }}
          />
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            ml={{ xs: "1%", sm: "3.5%", md: "2.5%" }}
            mr="1%"
            mt="2%"
          >
            <DataTableDashboard
              columns={column}
              rows={getGroupCropSummary.data}
            />
            <Box display="flex" width="100%" justifyContent="end">
              <Button
                variant="contained"
                size="small"
                sx={{
                  mt: "2%",
                  backgroundColor: "#005f40",
                  color: "#EDEDED",
                  fontSize: ".8rem",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#005f40",
                    color: "#EDEDED",
                  },
                }}
                onClick={() =>
                  exportToExcel(
                    getGroupCropSummary.data?.map(
                      ({ producer_id, ...rest }: any) => rest
                    ),
                    t("Dashboard.filter.groupCrop")
                  )
                }
              >
                {t("Dashboard.excel")}
              </Button>
            </Box>
          </Box>
        )}
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        {getFarmSummary.isLoading ? (
          <CircularProgress
            size={70}
            sx={{ color: "#005F40", ml: "50%", mt: "25%" }}
          />
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            ml={{ xs: "1%", sm: "3.5%", md: "2.5%" }}
            mr="1%"
            mt="2%"
          >
            <DataTableDashboard columns={column} rows={getFarmSummary.data} />
            <Box display="flex" width="100%" justifyContent="end">
              <Button
                variant="contained"
                size="small"
                sx={{
                  mt: "2%",
                  backgroundColor: "#005f40",
                  color: "#EDEDED",
                  fontSize: ".8rem",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#005f40",
                    color: "#EDEDED",
                  },
                }}
                onClick={() =>
                  exportToExcel(
                    getFarmSummary.data.map(
                      ({ farm_id, ...rest }: any) => rest
                    ),
                    t("Dashboard.filter.farm")
                  )
                }
              >
                {t("Dashboard.excel")}
              </Button>
            </Box>
          </Box>
        )}
      </TabPanel>
      <TabPanel value={activeTab} index={3}>
        {getFarmCropSummary.isLoading ? (
          <CircularProgress
            size={70}
            sx={{ color: "#005F40", ml: "50%", mt: "25%" }}
          />
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            ml={{ xs: "1%", sm: "3.5%", md: "2.5%" }}
            mr="1%"
            mt="2%"
          >
            <DataTableDashboard
              columns={column}
              rows={getFarmCropSummary.data}
            />
            <Box display="flex" width="100%" justifyContent="end">
              <Button
                variant="contained"
                size="small"
                sx={{
                  mt: "2%",
                  backgroundColor: "#005f40",
                  color: "#EDEDED",
                  fontSize: ".8rem",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#005f40",
                    color: "#EDEDED",
                  },
                }}
                onClick={() =>
                  exportToExcel(
                    getFarmCropSummary.data.map(
                      ({ farm_id, ...rest }: any) => rest
                    ),
                    t("Dashboard.filter.farmCrop")
                  )
                }
              >
                {t("Dashboard.excel")}
              </Button>
            </Box>
          </Box>
        )}
      </TabPanel>
    </div>
  );
};
